<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle
      title="Separation Form"
      slot="title"
      link="/helpContent/SeparationForm"
    />
    <ValidationObserver ref="validator">
      <span v-if="editCase" style="float: right;">{{ form.approvalStatus }}</span>
      <!-- <FormRow >
 
        <div class="col-sm-10"></div>
        <div class="col-sm-2">
          <p style="font-size: 16px;padding-bottom: 5px;color: #000;">
            Approval Status
          </p> 
         
        </div>
      </FormRow> -->
      <FormRow>
        <!-- <div class="col-sm-4"></div> -->
        <div class="col-sm-4">
          <FormSelect
            label="Select Staff"
            :items="staffList"
            item-name="Name"
            item-value="id"
            v-model="form.staffId"
            rules="required"
          />
        </div>
        <!-- <div class="col-sm-4"></div> -->
      </FormRow>
      <FormRow>
        <!-- <div class="col-sm-4"></div> -->
        <div class="col-sm-4">
          <FormSelect
            label="Nature of Separation"
            :items="separationList"
            item-name="name"
            item-value="id"
            v-model="form.separationNatureId"
            rules="required"
            :onChange="visableField"
          />
        </div>
        <!-- <div class="col-sm-4"></div> --></FormRow
      >
      <FormRow v-if="resignation">
        <!-- <div class="col-sm-4"></div> -->
        <div class="col-sm-4">
          <inputDate
            label="Resignation Date"
            v-model="form.resignationDate"
            rules="required"
          />
        </div>
        <div class="col-sm-1"></div>
        <div class="col-sm-6">Date resignation is submitted by staff. </div>
        </FormRow
      >
      <FormRow v-if="resignation">
        <!-- <div class="col-sm-4"></div> -->
        <div class="col-sm-4">
          <inputDate
            label="Effective Date"
            v-model="form.effectiveDate"
            rules="required"
          />
        </div>
        <div class="col-sm-1"></div>
        <div class="col-sm-6">Effective date is next working day from resignation date.</div>
      </FormRow>
      <FormRow>
        <!-- <div class="col-sm-4"></div> -->
        <div class="col-sm-4">
          <inputDate
            label="Requested Relieving Date"
            v-model="form.requestedRelievingDate"
            rules="required"
          />
        </div>
        <div class="col-sm-1"></div>
        <div class="col-sm-6">Date when staff member has requested to be relieved.</div>
        </FormRow
      >

      <FormRow>
        <!-- <div class="col-sm-4"></div> -->
        <div class="col-sm-1">
          <InputIntegerNumber
            label="Notice Period in Days"
            name="days"
            v-model="form.noticePeriod"
            rules="required"
          />
        </div>
        <!-- <div class="col-sm-4"></div> -->
        </FormRow
      >

      <FormRow>
        <!-- <div class="col-sm-4"></div> -->
        <div class="col-sm-4">
          <InputTextArea
            label="Notes by Staff"
            v-model="form.reason"
            rules="required"
          />
        </div>
        <!-- <div class="col-sm-4"></div> --></FormRow
      >
      <FormRow>
        <!-- <div class="col-sm-4"></div> -->
        <div class="col-sm-4">
          <InputTextArea
            label="Manager Notes"
            v-model="form.managerNotes"
            rules="required"
          />
        </div>
        <!-- <div class="col-sm-4"></div> -->
      </FormRow>
      <FormRow>
        <!-- <div class="col-sm-4"></div> -->
        <div class="col-sm-4">
          <label class="cheboxCl">Adjust EL</label>
          <InputYesNo
            :Yes="1"
          :No="0" v-model="form.isAdjustEL" name="Adjust EL" />
        </div>
          <div class="col-sm-1"></div>
        <div class="col-sm-6">Is staff member has Earned Leave balance the same can be adjusted at manager's discretion.</div>
      </FormRow>
      <FormRow>
        <!-- <div class="col-sm-4"></div> -->
        <div class="col-sm-4">
          <inputDate
            label="Official Relieving Date"
            v-model="form.officialRelievingDate"
            rules="required"
          />
        </div>
        <div class="col-sm-1"></div>
        <div class="col-sm-6">As per term of employment adding notice period. </div>
      </FormRow>

      <FormRow>
        <!-- <div class="col-sm-4"></div> -->
        <div class="col-sm-4">
          <inputDate
            label="Approved Relieving Date"
            v-model="form.approvedRelievingDate"
            rules="required"
          />
        </div>
        <div class="col-sm-1"></div>
        <div class="col-sm-4">Manager approved relieving date.</div>
      </FormRow>
      <FormRow>
        <!-- <div class="col-sm-4"></div> -->
        <div class="col-sm-4">
          <label class="cheboxCl">Required to attend office.</label>
          <InputYesNo
            :Yes="1"
          :No="0"
            v-model="form.isToAttendOffice"
            name="Required to Attend Office "
          />
        </div>
        <div class="col-sm-1"></div>
        <div class="col-sm-6">During notice period, whether staff must attend office.</div>
      </FormRow>
      <FormRow>
        <!-- <div class="col-sm-4"></div> -->
        <div class="col-sm-4">
          <label class="cheboxCl">Release salary during notice period.</label>
          <InputYesNo
            :Yes="1"
          :No="0"
            v-model="form.isSalaryReleased"
            name="Release Salary During Notice Period"
          />
        </div>
        <div class="col-sm-1"></div>
        <div class="col-sm-6">During notice period, salary can be released subject to manager's approval.</div>
        </FormRow
      >
      <FormRow>
        <!-- <div class="col-sm-4"></div> -->
        <div class="col-sm-4">
          <label class="cheboxCl">Waive Service Agreement</label>
          <InputYesNo
            :Yes="1"
          :No="0"
            v-model="form.isWaiveServiceAgreement"
            name="Waive Service Agreement"
          />
        </div>
        <div class="col-sm-1"></div>
        <div class="col-sm-6">If staff member has a service agreement it can be waived off subject to manager's approval.</div>
      </FormRow>
      <FormRow>
        <!-- <div class="col-sm-4"></div> -->
        <div class="col-sm-4">
          <inputDate
            label="Last Working Day"
            v-model="form.lastWorkingDay"
            rules="required"
          />
        </div>
        <div class="col-sm-1"></div>
        <div class="col-sm-6">Staff member gets paid till this  date.</div>
      </FormRow>
    </ValidationObserver>
    <br />
    <FormRow>
      <div class="col-sm-6">
        <ButtonGroup>
          <FormButton
            type="info"
            @click="
              () => {
                $router.go(-1);
              }
            "
            >Back</FormButton
          >
          <FormButton type="success" @click="sendData()">Save</FormButton>
        </ButtonGroup>
      </div>
    </FormRow>
    <Popup
      title="Success"
      type="success"
      :value="showMessage.isVisible"
      @close="() => (showMessage.isVisible = false)"
      :closable="true"
    >
      {{ showMessage.message }}
      <div slot="footer">
        <div slot="footer">
          <FormButton
            @click="
              () => {
                $router.go(-1);
              }
            "
            >Ok</FormButton
          >
        </div>
      </div>
    </Popup>
  </FormWrapper>
</template>

<script>
import FormWrapper from "Components/form/FormWrapper";
import PageTitle from "Components/layout/PageTitle";
import loadingMixin from "Mixins/loadingMixin";
import InputDate from "Components/form/InputDate";
import { getAllStaffByHRorManager, updateSeparationForm,separationList } from "../api";
import { ValidationObserver } from "vee-validate";

export default {
  name: "add",
  mixins: [loadingMixin],
  components: {
    FormWrapper,
    PageTitle,
    ValidationObserver,
    InputDate,
  },
  data() {
    return {
      showMessage: {
        isVisible: false,
      },
      form: {
        staffId: null,
        resignationDate: "",
        separationNatureId: null,
        effectiveDate: "",
        requestedRelievingDate: "",
        noticePeriod:null,
        reason: "",
        managerNotes: "",
        isAdjustEL: "",
        officialRelievingDate: "",
        approvedRelievingDate: "",
        isToAttendOffice: "",
        isSalaryReleased: "",
        isWaiveServiceAgreement: "",
        lastWorkingDay: "",
        approvalStatus: "Pending approval",
        separationStatusId:null,
        id: 0,
      },
editCase:false,
      staffList: [],
      separationList: [
        {
          id: "R",
          name: "Resignation",
        },
        {
          id: "T",
          name: "Termination",
        },
        {
          id: "A",
          name: "Absconding",
        },
      ],
      resignation:false,
      termination:false,
      absconding:false,
    };
  },
  computed: {
    userData() {
      return this.$store.state.user.user;
    },
  },
  watch: {},
  created() {
    this.getAllList();
  },
  methods: {
    visableField(e) {
    if (e==='R') {
      this.resignation=true;
      this.termination=false;
      this.absconding=false; 
    } else if(e==='T') {
      this.termination=true;
      this.resignation=false;
      this.absconding=false; 
      this.form.resignationDate='';
      this.form.effectiveDate='';
    }
    else if(e==='A') {
      this.absconding=true; 
      this.termination=false;
      this.resignation=false;
      this.form.resignationDate='';
      this.form.effectiveDate='';
    }
    },
    sendData() {
      this.$refs.validator.validate().then((result) => {
        if (result) {
          this.showMessage.isVisible = false;

          const data = {
            staffId: this.form.staffId,
            resignationDate: this.form.resignationDate,
            separationNatureId: this.form.separationNatureId,
            effectiveDate: this.form.effectiveDate,
            requestedRelievingDate: this.form.requestedRelievingDate,
            noticePeriod: this.form.noticePeriod,
            reason: this.form.reason,
            managerNotes: this.form.managerNotes,
            isAdjustEL: this.form.isAdjustEL,
            officialRelievingDate: this.form.officialRelievingDate,
            approvedRelievingDate: this.form.approvedRelievingDate,
            isToAttendOffice: this.form.isToAttendOffice,
            isSalaryReleased: this.form.isSalaryReleased,
            isWaiveServiceAgreement: this.form.isWaiveServiceAgreement,
            lastWorkingDay: this.form.lastWorkingDay,
            separationStatusId:this.form.separationStatusId,
            id: this.form.id,
          };
          updateSeparationForm(data).then((res) => {
            this.form = {};
            this.showMessage.isVisible = true;
            this.showMessage.message = res.data[0].message;
          });
        }
      });
    },
    async getAllList() {
      await getAllStaffByHRorManager().then((res) => {
        this.staffList = res.data;
      });
      if (this.$route.params.id) {
        this.form.id=atob(this.$route.params.id);
        this.getData();
        this.editCase=true;
      }
    },
    getData(){
      const filters = {};
      filters.id=this.form.id
      const data = {
        filterjson: {
          filter: [filters],
        },
      };
      separationList(data).then(res=>{
        if (res.data[0].SeparationNature_Id==='R') {
      this.resignation=true;
      this.termination=false;
      this.absconding=false; 
    } else if(res.data[0].SeparationNature_Id==='T') {
      this.termination=true;
      this.resignation=false;
      this.absconding=false; 
      this.form.resignationDate='';
      this.form.effectiveDate='';
    }
    else if(res.data[0].SeparationNature_Id==='A') {
      this.absconding=true; 
      this.termination=false;
      this.resignation=false;
      this.form.resignationDate='';
      this.form.effectiveDate='';
    }
        this.form.staffId= res.data[0].Staff_Id;
        this.form.resignationDate= res.data[0].ResignationDate;
        this.form.separationNatureId=res.data[0].SeparationNature_Id;
        this.form.effectiveDate=res.data[0].EffectiveResignationDate;
        this.form.requestedRelievingDate=res.data[0].RelievingWishDate;
        this.form.noticePeriod=res.data[0].NoticePeriod;
        this.form.reason=res.data[0].Reason;
        this.form.managerNotes=res.data[0].ManagerNotes;
        this.form.isAdjustEL=res.data[0].IsAdjustEL;
        this.form.officialRelievingDate=res.data[0].OfficialRelievingDate;
        this.form.approvedRelievingDate=res.data[0].ApprovedRelievingDate;
        this.form.isToAttendOffice=res.data[0].IsAttendOffice;
        this.form.isSalaryReleased=res.data[0].IsSalaryReleased;
        this.form.isWaiveServiceAgreement=res.data[0].IsWaiveServiceAgreement;
        this.form.lastWorkingDay=res.data[0].LastWorkingDate;
        this.form.separationStatusId=res.data[0].separationStatusId;
        this.form.approvalStatus=res.data[0].Separationstatus;
            
      })
    },
  },
};
</script>
<style lang="scss">
.cheboxCl {
  color: #555;
  font-size: 13px;
  line-height: 19px;
  font-weight: 400;
}
textarea {
  height: 220px !important;
}
</style>
